import * as React from 'react';
import {KeyboardEvent} from 'react';
import {withStyles} from 'material-ui/styles';

import {styles} from './Map.style';

const MapViewComponent = (props) => (
  <div>
    <div
      className={props.classes.map}
      id="map-container"
      onKeyPress={props.onKeyPress}
      tabIndex={1}
    />
    <input id="pac-input" className="map-controls pac-input" type="text" placeholder="Search Box" />
    <div className="map-actions">
      <button type="button" className="btn btn-default btn-google-maps" onClick={props.onClear}>
        Clear Viewshed
      </button>
    </div>
  </div>
);

export const MapView = withStyles(styles)(MapViewComponent);
