import {GeofenceLayer} from 'models';

type Popups = {[layer in GeofenceLayer]: (feature: any) => string};

const fieldTemplate = (name, value) =>
  value
    ? `<div>
      <strong>${name}:</strong>
      <span>${value}</span>
    </div>`
    : '';

const popupTemplate = (title, fields) => `
  <div class="mapbox-popup">
    <h6><strong>${title}</strong></h6>

    ${Object.keys(fields)
      .map((key) => fieldTemplate(key, fields[key]))
      .join()
      .replace(/<\/div>,*/g, '</div>')}
  </div>`;

export const popupTemplates = {
  state: (feature: any) =>
    popupTemplate(feature.properties.name, {
      'Geo ID': feature.properties.geoId,
      Code: feature.properties.code,
    }),
  county: (feature: any) =>
    popupTemplate(`${feature.properties.name}, ${feature.properties.stateCode}`, {
      'Geo ID': feature.properties.geoId,
      State: feature.properties.state,
    }),
  zip: (feature: any) =>
    popupTemplate(feature.properties.name, {
      'Geo ID': feature.properties.geoId,
      State: feature.properties.state,
      County: feature.properties.county,
      Locality: feature.properties.locality,
    }),
  place: (feature: any) =>
    popupTemplate(feature.properties.name, {
      'Geo ID': feature.properties.geoId,
      State: feature.properties.state,
    }),
  csa: (feature: any) =>
    popupTemplate(feature.properties.name, {
      'Geo ID': feature.properties.geoId,
      State: feature.properties.state && JSON.parse(feature.properties.state).join(', '),
      Locality: feature.properties.locality && JSON.parse(feature.properties.locality).join(', '),
    }),
  cbsa: (feature: any) =>
    popupTemplate(feature.properties.name, {
      'Geo ID': feature.properties.geoId,
      State: feature.properties.state && JSON.parse(feature.properties.state).join(', '),
      Locality: feature.properties.locality && JSON.parse(feature.properties.locality).join(', '),
    }),
  'census-tract': (feature: any) =>
    popupTemplate(feature.properties.name, {
      'Geo ID': feature.properties.geoId,
      State: feature.properties.state,
      County: feature.properties.county,
      Locality: feature.properties.locality && JSON.parse(feature.properties.locality).join(', '),
    }),
  'block-group': (feature: any) =>
    popupTemplate(feature.properties.name, {
      'Geo ID': feature.properties.geoId,
      State: feature.properties.state,
      County: feature.properties.county,
      Locality: feature.properties.locality,
      'Zip Code': feature.properties.zip,
      Neighborhood:
        feature.properties.neighborhood && JSON.parse(feature.properties.neighborhood).join(', '),
      CSA: feature.properties.csa,
      CBSA: feature.properties.cbsa,
    }),
  neighborhood: (feature: any) =>
    popupTemplate(feature.properties.name, {
      'Geo ID': feature.properties.geoId,
      State: feature.properties.state,
      County: feature.properties.county,
      Locality: feature.properties.locality,
      'Zip Code': feature.properties.zip,
    }),
};
