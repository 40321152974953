import {Query} from 'bkn-ui';
import {combineReducers} from 'redux';
import {Action, createAction, createReducer} from 'redux-act';
import {delay, eventChannel} from 'redux-saga';
import {call, fork, put, select, take, takeLatest} from 'redux-saga/effects';

import {RootState} from 'config/redux';
import {GeofenceCollectionItem} from 'models';
import {safe} from 'utils';

import {GeofenceCollectionRestService} from '../../../../services';
import {downloadGeoJSON, toGeoJSON} from '../../../../services/ExportGeoJSONService';
import {actions as globalActions} from '../../global';
import {actions} from './actions';

// SAGAS

export const fetchDataSaga = safe(function*() {
  try {
    yield put(globalActions.showLoading());

    yield put(actions.setTotal(0));
    yield put(actions.setData(null));

    const {data, total} = yield GeofenceCollectionRestService.getInstance().find({
      fields: ['name', 'updatedAt'],
    });

    yield put(actions.setTotal(total));
    yield put(actions.setData(data));
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const addCollectionSaga = safe(function*(action: Action<string>) {
  try {
    yield put(globalActions.showLoading());

    const collection = yield GeofenceCollectionRestService.getInstance().create({
      name: action.payload,
    });

    const {data, total} = yield select((state: RootState) => state.geofenceCollection);
    const parsedData = Object.keys(data).map((key) => data[key]);
    yield put(actions.setTotal(total + 1));
    yield put(actions.setData([...parsedData, collection]));
    yield put(actions.setSelectedCollection(collection.id));
    yield put(globalActions.showMessage('Collection saved'));
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const updateCollectionSaga = safe(function*(action: Action<GeofenceCollectionItem>) {
  try {
    yield put(globalActions.showLoading());
    const {id, ...props} = action.payload;
    const collection = yield select((state: RootState) => state.geofenceCollection.data[id]);

    yield put(actions.setGeofenceCollectionItem({...collection, ...props}));
    const res = yield GeofenceCollectionRestService.getInstance().update(id, {
      ...collection,
      ...props,
    });
    yield put(actions.setGeofenceCollectionItem(res));

    yield put(globalActions.showMessage('Collection saved'));
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const removeCollectionSaga = safe(function*() {
  try {
    yield put(globalActions.showLoading());

    const collection = yield select((state: RootState) => state.geofenceCollection.selected);
    const {data, total} = yield select((state: RootState) => state.geofenceCollection);
    yield put(actions.setTotal(total - 1));
    yield put(actions.removeCollectionItem(collection));

    try {
      yield GeofenceCollectionRestService.getInstance().remove(collection);
      yield put(globalActions.showMessage('Collection removed'));
    } catch {
      yield put(globalActions.showMessage('Error removing collection'));
      const parsedData = Object.keys(data).map((key) => data[key]);
      yield put(actions.setData([...parsedData, collection]));
    }
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const exportCollectionSaga = safe(function*() {
  try {
    yield put(globalActions.showLoading());

    const collectionId = yield select((state: RootState) => state.geofenceCollection.selected);
    const geofenceId = yield select((state: RootState) => state.geofence.selected);
    const collection = yield select(
      (state: RootState) => state.geofenceCollection.data[collectionId],
    );
    const geofences = yield select((state: RootState) => state.geofence.data);
    const geoJSONContent = yield toGeoJSON(collection, geofences);
    yield downloadGeoJSON(collection, geoJSONContent);
    yield put(globalActions.showMessage('Collection exported'));
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const geofenceCollectionFlow = safe(function*() {
  yield takeLatest(actions.fetchData, fetchDataSaga);
  yield takeLatest(actions.addCollection, addCollectionSaga);
  yield takeLatest(actions.updateCollection, updateCollectionSaga);
  yield takeLatest(actions.removeCollection, removeCollectionSaga);
  yield takeLatest(actions.exportCollection, exportCollectionSaga);
});

export const geofenceCollectionSaga = safe(function*() {
  yield fork(geofenceCollectionFlow);
});
