import {Schema} from 'bkn-form';
import {Action} from 'redux';
import {createAction} from 'redux-act';

import {Breadcrumb} from 'models';

export const actions = {
  navigateToRoute: createAction<string>('app/ROUTE_CHANGE'),
  setBreadcrumbs: createAction<Breadcrumb[]>('app/BREADCRUMBS_CHANGE'),
  setPageTitle: createAction<string>('app/PAGE_TITLE_CHANGE'),

  showLoading: createAction('app/SHOW_LOADING'),
  hideLoading: createAction('app/HIDE_LOADING'),

  showMessage: createAction<string | {title?: string; action?: 'loading' | ''}>('app/SHOW_MESSAGE'),
  hideMessage: createAction('app/HIDE_MESSAGE'),

  swUpdated: createAction('app/SERVICE_WORKER_UPDATED'),
  swQuotaExceeded: createAction('app/SERVICE_QUOTA_EXCEEDED'),
  swWontUpdate: createAction('app/SERVICE_WORKER_WONT_UPDATE'),
  swRefresh: createAction('app/SERVICE_WORKER_REFRESH'),
};
