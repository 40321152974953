import {createSelector} from 'reselect';
import {$call} from 'utility-types';

import {Dispatch, RootState} from 'config/redux';
import {actions as geofenceActions} from 'config/redux/modules/geofence';
import {GeofenceItem} from 'models';

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveGeofence: (geofence: Partial<GeofenceItem>) =>
    dispatch(geofenceActions.updateGeofence(geofence)),
  removeGeofence: () => dispatch(geofenceActions.removeGeofence()),
});

const mapDispatchToPropsNope = $call(mapDispatchToProps);
export type MapDispatchToProps = typeof mapDispatchToPropsNope;
