import * as React from 'react';
import {SFC} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from 'components/common/Layout';

import {CustomContainer, PoliticalContainer} from './pages';

export const App: SFC<{}> = () => (
  <Layout>
    <Switch>
      <Route path="/political" component={PoliticalContainer} />
      <Route exact path="/custom" component={CustomContainer} />
      <Redirect to="/political" />
    </Switch>
  </Layout>
);

App.displayName = 'App';
