import {beakynPurple} from 'utils';

const purple = beakynPurple;

export const styles = (theme) => ({
  header: {
    display: 'flex',
    padding: 20,
    borderWidth: '1px 0px 1px 0px',
  },
  dialog: {
    root: {
      height: 600,
      maxHeight: 600,
    },
  },
  dialogContent: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    width: 600,
  },
});
