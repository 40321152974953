import * as React from 'react';
import bbox from '@turf/bbox';
import * as _get from 'lodash.get';

import {Geometry} from 'models';

import {AddFormContainer} from '../AddForm';
import {MapView} from './Map.view';

type Props = {
  viewshed: Geometry;
  centroid: number[];
  geofenceId: string;
  onLocationChange: (obj: Geometry) => void;
};

export class MapContainer extends React.Component<Props> {
  private map: google.maps.Map;
  private centerMarker: any;
  private defaultLat: number = 38.4363655;
  private defaultLng: number = -96.4002259;
  private defaultZoom: number = 4;
  private polygons: google.maps.Polygon[] = [];
  private listeners: any[] = [];
  private polygonsListeners: any[] = [];
  private drawingManager: google.maps.drawing.DrawingManager;
  private mapContainer: HTMLDivElement;

  shouldComponentUpdate() {
    return false;
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.geofenceId !== this.props.geofenceId) {
      this.centerMarker.setPosition(null);
      this.setPolygons(nextProps);
      this.mapContainer.focus();
    }
  }

  componentDidMount() {
    this.mapContainer = document.getElementById('map-container') as HTMLDivElement;
    this.initMap();
    this.mapContainer.focus();
  }

  render() {
    return (
      <MapView
        {...this.props}
        onClear={this.clearPolygonsAndViewshed}
        onKeyPress={this.onMapKeyPress}
      />
    );
  }

  initMap = () => {
    let coordinates = {
      lat: this.defaultLat,
      lng: this.defaultLng,
    };

    this.map = new window.google.maps.Map(this.mapContainer, {
      zoom: this.defaultZoom,
      center: coordinates,
      rotateControl: false,
      tilt: 0,
    });

    // Create center marker.
    this.centerMarker = new google.maps.Marker({
      map: this.map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      zIndex: 20,
      position: null,
    });

    const input = document.getElementById('pac-input');
    const searchBox = new window.google.maps.places.SearchBox(input);
    this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // For each place, get the icon, name and location.
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place: any) => {
        if (!place.geometry) {
          console.log('Returned place contains no geometry');
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
      const mapCenter = this.map.getCenter();
      const updatedCenter = googleToGeoJSON(mapCenter);
      this.dropCenterMarker(updatedCenter);
    });
    this.buildViewshedManager();
  };

  initDrawingManager = () => {
    const options: any = {
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT,
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        clickable: true,
        editable: true,
        strokeColor: '#7f20d0',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#7f20d0',
        fillOpacity: 0.35,
        zIndex: 1,
      },
    };
    return new window.google.maps.drawing.DrawingManager(options);
  };

  buildViewshedManager = () => {
    // Init drawingManager
    this.drawingManager = this.initDrawingManager();

    // Add drawingManager to map.
    this.drawingManager.setMap(this.map);

    window.google.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      (polygon: google.maps.Polygon) => {
        this.polygons.push(polygon);

        this.updateViewshed();

        this.listeners.push(
          window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
            this.updateViewshed();
          }),
        );
      },
    );
    this.setPolygons(this.props);
  };

  clearPolygonsAndViewshed = () => {
    this.clearPolygons();
    this.updateViewshed();
  };

  clearPolygons = () => {
    this.listeners.forEach((l) => window.google.maps.event.removeListener(l));
    this.listeners = [];

    this.polygons.forEach((p) => {
      p.setMap(null);
    });
    this.polygons = [];
  };

  dropCenterMarker = (center: any) => {
    const coordinates = center.coordinates;
    const position = new google.maps.LatLng(coordinates[1], coordinates[0]);

    this.centerMarker.setPosition(position);
  };

  setPolygons = (props: Props) => {
    this.clearPolygons();
    this.focusPolygons(props);
    const hasPolygon = _get(props.viewshed, 'coordinates[0]', false);

    // if (viewshed && viewshed.coordinates && viewshed.coordinates[0]) {
    if (!hasPolygon) {
      return;
    }

    let polyPoints = [] as google.maps.LatLng[][];
    const oldFormat = hasPolygon[0].length === 2 && typeof hasPolygon[0][0] === 'number';

    if (oldFormat) {
      polyPoints.push([]);
      props.viewshed.coordinates[0].forEach((p) =>
        polyPoints[0].push(new window.google.maps.LatLng(p[1], p[0])),
      );
    } else {
      props.viewshed.coordinates.forEach((polygons) => {
        polyPoints.push(polygons[0].map((p) => new window.google.maps.LatLng(p[1], p[0])));
      });
    }

    this.polygons = polyPoints.map(
      (p) =>
        new window.google.maps.Polygon({
          // Remove last point array becouse it repeats first.
          paths: p.slice(0, -1),
          editable: true,
          strokeColor: '#7f20d0',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#7f20d0',
          fillOpacity: 0.35,
          zIndex: 1,
        }),
    );

    this.polygons.forEach((p) => {
      p.setMap(this.map);

      this.listeners.push(
        window.google.maps.event.addListener(p.getPath(), 'set_at', () => {
          this.updateViewshed();
        }),

        window.google.maps.event.addListener(p.getPath(), 'insert_at', () => {
          this.updateViewshed();
        }),
      );
    });

    if (oldFormat) {
      this.updateViewshed();
    }
  };

  focusPolygons = (props: Props) => {
    if (props.viewshed) {
      const bbounds = bbox(props.viewshed);
      const [west, south, east, north] = bbounds;
      const southWest = new google.maps.LatLng(south, west);
      const northEast = new google.maps.LatLng(north, east);
      const latLngBounds = new google.maps.LatLngBounds(southWest, northEast);
      this.map.fitBounds(latLngBounds);
    } else if (props.centroid) {
      const coords = [...props.centroid];
      coords.reverse();
      this.map.setCenter(new window.google.maps.LatLng(...coords));
      this.map.setZoom(this.defaultZoom);
    }
  };

  updateViewshed = () => {
    const viewshed = this.buildPolygonGeometry(this.polygons);
    this.props.onLocationChange(viewshed);
  };

  buildPolygonGeometry = (googleShape: google.maps.Polygon[]) => {
    return {
      type: 'MultiPolygon',
      coordinates: googleShape.map((p) => {
        const len = p.getPath().getLength();
        const coords = [];

        for (let i = 0; i < len; i += 1) {
          let point = p
            .getPath()
            .getAt(i)
            .toUrlValue(14)
            .split(',');
          coords.push([Number(point[1]), Number(point[0])]);
        }

        coords.push(coords[0]);
        return [coords];
      }),
    };
  };

  onMapKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const spaceCharCode = 32;

    if (event.charCode === spaceCharCode) {
      const drawingMode = this.drawingManager.getDrawingMode()
        ? null
        : google.maps.drawing.OverlayType.POLYGON;

      this.drawingManager.setDrawingMode(drawingMode);
    }
  };
}

function googleToGeoJSON(latLng: any) {
  return {
    type: 'Point',
    coordinates: [latLng.lng(), latLng.lat()],
  };
}
