import {Query} from 'bkn-ui';
import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import {Geofences, IndexedGeofence} from 'models';

import {actions} from './actions';

export const initialState = {
  data: {} as IndexedGeofence,
  selected: null as string,
  searchResult: [] as string[],
  searchTerm: '',
  query: {
    limit: 50,
    skip: 0,
    sort: 'name',
  } as Query,
  total: 0,
};

const data = createReducer({}, initialState.data)
  .on(actions.setData, (state, payload) => {
    return payload
      ? payload.reduce((curr, next) => {
          curr[next.id] = next;
          return curr;
        }, {}) || {}
      : null;
  })
  .on(actions.setGeofenceItem, (state, payload) => {
    return {...state, [payload.id]: payload};
  })
  .on(actions.removeGeofenceItem, (state, payload) => {
    return (
      Object.keys(state)
        .filter((key) => key !== payload)
        .reduce((curr, next) => {
          curr[next] = state[next];
          return curr;
        }, {}) || {}
    );
  });

const searchResult = createReducer({}, initialState.searchResult).on(
  actions.setSearchResult,
  (state, payload) => payload.map((item) => item.id),
);

const query = createReducer({}, initialState.query).on(
  actions.setQuery,
  (state, payload) => payload,
);

const searchTerm = createReducer({}, initialState.searchTerm).on(
  actions.setSearchTerm,
  (state, payload) => payload,
);

const total = createReducer({}, initialState.total).on(
  actions.setTotal,
  (state, payload) => payload,
);

const selected = createReducer({}, initialState.selected).on(
  actions.setSelectedGeofence,
  (state, payload) => payload,
);

export type GeofenceState = typeof initialState;
export const geofence = combineReducers<GeofenceState>({
  searchResult,
  data,
  query,
  searchTerm,
  total,
  selected,
});
