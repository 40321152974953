import {push} from 'react-router-redux';
import {Action} from 'redux-act';
import {put} from 'redux-saga/effects';

import {safe} from 'utils';

export function* navigateToRoute(action: Action<string>) {
  const path = action.payload;
  yield put(push(path));
}

export const redirectToHomePage = safe(function*() {
  yield put(push('/'));
});
