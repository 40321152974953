import {$call} from 'utility-types';

import {RootState} from 'config/redux/rootReducer';

export const mapStateToProps = (state: RootState) => ({
  loading: state.app.loading,
});

const mapStateToPropsNope = $call(mapStateToProps);
export type MapStateToProps = typeof mapStateToPropsNope;
