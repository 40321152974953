import * as React from 'react';
import {HTMLProps, SFC} from 'react';
import * as classNames from 'classnames';

interface Props extends HTMLProps<HTMLDivElement> {}

export const Map: SFC<Props> = ({children, ...otherProps}: Props) => (
  <div id="map" {...otherProps}>
    {children}
  </div>
);

Map.displayName = 'Map';
