import {actions} from 'config/redux/global/actions';

export const swConnect = (store) => {
  if ('serviceWorker' in navigator) {
    // Handler for messages coming from the service worker
    window.addEventListener('message', function(event: MessageEvent) {
      const swUpdated = actions.swUpdated();
      const swQuotaExceeded = actions.swQuotaExceeded();
      if (event.data === 'SERVICE_WORKER_UPDATED') {
        store.dispatch(swUpdated);
      } else if (event.data === 'SERVICE_QUOTA_EXCEEDED') {
        store.dispatch(swQuotaExceeded);
      }
    });
  }
};
