import {fork} from 'redux-saga/effects';

import {safe} from 'utils';

import {serviceWorkerSaga} from './serviceWorker';

//  Helpers
//  Actions
//  Sagas

export const appSaga = safe(function* () {
  yield fork(serviceWorkerSaga);
});
