import * as React from 'react';
import {FormWidget} from 'bkn-form';
import {Datagrid, DatagridFilter, DatagridFiltersSlot, DatagridMapView, ViewMode} from 'bkn-ui';
import Paper from 'material-ui/Paper';

import {Map} from 'components';

export const PoliticalView = ({
  clearMapInfo,
  layerItems,
  handleLayerChange,
  layer,
  subtype,
  subtypeItems,
  shouldRenderSubtypeFilter,
  handleSubtypeChange,
}) => (
    <Datagrid noDefaultFilter noFilters noHeader noPagination viewMode={ViewMode.Map}>
      <DatagridMapView>
        <Map className="test" onMouseLeave={clearMapInfo} />
        <div className="map-layer-controls-view">
          <Paper className="map-layer-controls-view-menu">
            <section className="mdc-dialog__body--scrollable">
              <div className="layer-controls">
                <div className="control">
                  <h4>Type</h4>
                  <div className="body">
                    <FormWidget.Select
                      name={'Select...'}
                      onlyInput
                      searchable
                      options={layerItems}
                      onChange={handleLayerChange}
                      value={layer}
                    />
                  </div>
                </div>
                {shouldRenderSubtypeFilter() && (
                  <div className="control">
                    <h4>Subtype</h4>
                    <div className="body">
                      <FormWidget.Select
                        name={'Select...'}
                        onlyInput
                        searchable
                        options={subtypeItems}
                        onChange={handleSubtypeChange}
                        value={subtype}
                      />
                    </div>
                  </div>
                )}
              </div>
            </section>
          </Paper>
        </div>
      </DatagridMapView>
    </Datagrid>
);
