import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import {$call, $PropertyType} from 'utility-types';

import {actions} from './actions';

const breadcrumbResult = $call(actions.setBreadcrumbs);
const messageResult = $call(actions.showMessage);
type Breadcrumb = $PropertyType<typeof breadcrumbResult, 'payload'>[number];
type Message = $PropertyType<typeof messageResult, 'payload'>;

export const initialState = {
  breadcrumbs: [] as Breadcrumb[],
  pageTitle: '',
  message: {} as Message,
  hasServiceWorkerUpdate: false,
  hasServiceWorkerQuotaExceeded: false,
  messageOpened: false,
  loading: false,
};

const breadcrumbs = createReducer({}, initialState.breadcrumbs).on(
  actions.setBreadcrumbs,
  (state, payload) => [...payload],
);

const pageTitle = createReducer({}, initialState.pageTitle).on(
  actions.setPageTitle,
  (state, payload) => payload,
);

const loading = createReducer({}, initialState.loading)
  .on(actions.hideLoading, (state, payload) => false)
  .on(actions.showLoading, (state, payload) => true);

const messageOpened = createReducer({}, initialState.messageOpened)
  .on(actions.hideMessage, (state, payload) => false)
  .on(actions.showMessage, (state, payload) => true);

const message = createReducer({}, initialState.message).on(
  actions.showMessage,
  (state, payload) =>
    typeof payload === 'string'
      ? {
          title: payload,
          toString() {
            return payload;
          },
        }
      : {
          ...payload,
          toString() {
            return payload.title;
          },
        },
);

const hasServiceWorkerUpdate = createReducer({}, initialState.hasServiceWorkerUpdate)
  .on(actions.swUpdated, (state, payload) => true)
  .on(actions.swWontUpdate, (state, payload) => false);

const hasServiceWorkerQuotaExceeded = createReducer({}, initialState.hasServiceWorkerQuotaExceeded)
  .on(actions.swQuotaExceeded, (state, payload) => true)
  .on(actions.swWontUpdate, (state, payload) => false);

export type AppState = typeof initialState;
export const app = combineReducers<AppState>({
  breadcrumbs,
  pageTitle,
  loading,
  message,
  messageOpened,
  hasServiceWorkerUpdate,
  hasServiceWorkerQuotaExceeded,
});
