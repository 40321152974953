import * as ParserWorker from 'worker-loader?inline&fallback=false!./ExportGeoJSON.worker';

let worker = new ParserWorker();

export const toGeoJSON = (collection, geofences) => {
  worker.postMessage({
    type: 'TO_GEOJSON',
    collection,
    geofences,
  });

  return new Promise((resolve) => {
    const handler = (e) => {
      if (e.data.type === 'TO_GEOJSON_FINISHED') {
        worker.removeEventListener('message', handler);
        resolve(e.data.result);
      }
    };

    worker.addEventListener('message', handler);
  });
};

export const downloadGeoJSON = (collection, content) => {
  const a = document.createElement('a');
  const mimeType = 'application/octet-stream';
  const fileName = `${collection.name}.json`;

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName,
    );
  } else if (URL && 'download' in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      }),
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    (location as any).href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
  }
};
