import createRavenMiddleware from 'raven-for-redux';
import * as Raven from 'raven-js';

const stateTransformer = (state) => {
  return {
    user: state.user,
    router: state.router,
  };
};

class RavenService {
  public static instance: RavenService;
  public Raven;
  public middleware;

  constructor() {
    if (process.env.NODE_ENV === 'production') {
      Raven.config('https://ea7d8bc949684370a2f833e0515c45f7@o489580.ingest.sentry.io/932662', {
        release: process.env.COMMITHASH,
      }).install();
    }

    this.Raven = Raven;
    this.middleware = createRavenMiddleware(Raven, {
      stateTransformer,
    });
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RavenService();
    }

    return this.instance;
  }

  report = (ex: Error | ErrorEvent | string) => {
    Raven.captureException(ex);
  };
}

export default new RavenService();
