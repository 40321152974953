import {takeLatest} from 'redux-saga/effects';

import {safe} from 'utils';

import {actions} from '../actions';

const swRefreshSaga = safe(function*() {
  const names = yield caches.keys();
  for (let name of names) caches.delete(name);
  window.location.reload(true);
});

export const serviceWorkerSaga = safe(function*() {
  yield takeLatest(actions.swRefresh, swRefreshSaga);
});
