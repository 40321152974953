import * as React from 'react';
import {Link} from 'react-router-dom';
import {Nav, NavItem, NavLink} from 'reactstrap';
import * as classNames from 'classnames';

interface Props {
  activeNavLink: string;
  onClickLink: () => void;
}

export const DrawerContent = ({activeNavLink, onClickLink}: Props) => (
  <div className="drawer-nav-wrapper">
    <Nav vertical>
      <NavItem>
        <NavLink
          className={classNames('toggle-drawer', {active: activeNavLink.startsWith('#/political')})}
          tag={Link}
          to="/political"
          onClick={onClickLink}
        >
          Political
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          className={classNames('toggle-drawer', {active: activeNavLink.startsWith('#/custom')})}
          tag={Link}
          to="/custom"
          onClick={onClickLink}
        >
          Custom
        </NavLink>
      </NavItem>
    </Nav>

    <div className="brands">
      <img
        src="https://storage.googleapis.com/outfront/assets/images/beakyn-brand.png"
        width="32"
        height="auto"
        alt="Beakyn Company"
      />
      <img
        src="https://storage.googleapis.com/outfront/assets/images/ofm-lock-beand-white.png"
        width="50"
        height="auto"
        alt="Outfront Media"
      />
    </div>

    <div className="drawer-footer">
      <p>
        Made by Beakyn Company in Brazil <br />
        <a href="mailto:contact@beakyn.com" title="Contact Us">
          contact@beakyn.com
        </a>
      </p>
    </div>
  </div>
);
