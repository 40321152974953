import * as React from 'react';
import * as _get from 'lodash.get';
import AppBar from 'material-ui/AppBar';
import Button from 'material-ui/Button';
import {CircularProgress} from 'material-ui/Progress';
import {withStyles} from 'material-ui/styles';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';

import {MapContainer} from '../Map';
import {OptionsContainer} from '../Options';

export const GeofenceView = (props) => {
  const modelName = 'Geofence';
  const geofenceName = _get(props.geofences, `${props.selectedGeofence}.name`);
  return (
    <div className={[props.classes.column, props.classes.columnMap].join(' ')}>
      <ListHeader title={geofenceName} {...props}>
        {props.selectedGeofence && (
          <Button color="primary" onClick={props.onSave} disabled={!props.valid}>
            Save
          </Button>
        )}
        {props.selectedGeofence && (
          <OptionsContainer
            editEntity={modelName}
            editEntityName={geofenceName}
            onSubmitEdit={props.onEdit}
            removeEntity={modelName}
            removeEntityName={geofenceName}
            onSubmitRemove={props.onRemove}
            selectedCollection={props.selectedCollection}
            selectedGeofence={props.selectedGeofence}
          />
        )}
      </ListHeader>
      <div className={props.classes.columnContent}>
        {props.geofences ? <MapBody {...props} /> : <Loading {...props} />}
      </div>
    </div>
  );
};

const ListHeader = (props) => (
  <AppBar position="static" color="default">
    <Toolbar className={props.classes.columnToolBar}>
      <Typography variant="subheading" className={props.classes.columnToolBarTitle}>
        {props.title}
      </Typography>
      {props.children}
    </Toolbar>
  </AppBar>
);

const MapBody = (props) => {
  return props.selectedGeofence ? (
    <MapContainer
      geofenceId={props.geofenceId}
      viewshed={props.currentViewshed}
      centroid={_get(props.geofences, `${props.selectedGeofence}.centroid`)}
      onLocationChange={props.onLocationChange}
    />
  ) : null;
};

const Loading = (props) => (
  <div className={props.classes.loading}>
    <CircularProgress size={24} thickness={5} />
  </div>
);
