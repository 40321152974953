import * as React from 'react';
import {MouseEvent, SFC} from 'react';
import {UserMenu as BknUserMenu, UserMenuImage} from 'bkn-ui';
import {Button, Divider, Typography} from 'material-ui';
import withStyles, {StyledComponentProps} from 'material-ui/styles/withStyles';

import {styles} from 'components/common/Layout/UserMenu.styles';
import {environment} from 'config';
import {compose} from 'utils/functional';
import {truncate} from 'utils/string';
import {getAccountLink} from 'utils/url';

import {User} from '../../../models/User';

interface Props {
  loading: boolean;
  user: User;
  onClickLogoutButton: (e: MouseEvent<HTMLAnchorElement>) => void;
}

interface UserMenuState {
  open: boolean;
}

const linkToAccount = getAccountLink();

@compose(withStyles(styles))
export class UserMenu extends React.Component<Props & StyledComponentProps, UserMenuState> {
  state: UserMenuState = {
    open: false,
  };

  renderUserMenuImage = (user: User) => {
    return <UserMenuImage imageUrl={user.picture || ''} userName={user.name || ''} />;
  };

  onButtonClick = () => {
    this.setState({open: false});
  };

  render() {
    const {user, onClickLogoutButton, classes} = this.props;
    const {open} = this.state;

    return (
      <BknUserMenu open={open} userImage={this.renderUserMenuImage(user)}>
        <div className={classes.accountMenuPopoverBody}>
          <Typography gutterBottom className={classes.typography}>
            <b>{user.name || ''}</b>
            <br />
            <small>{truncate(user.email || '', 28)}</small>
          </Typography>

          <div className={classes.buttonWrapper}>
            <Button
              onClick={this.onButtonClick}
              target="_blank"
              href={linkToAccount}
              size="small"
              color="primary"
              className={classes.button}
            >
              Account
            </Button>
            &nbsp;|&nbsp;
            <Button
              onClick={onClickLogoutButton}
              size="small"
              color="primary"
              className={classes.button}
            >
              Logout
            </Button>
          </div>

          <Divider />

          <Typography className={classes.versionLabel}>
            v{environment.settings.app.version} - build {environment.settings.app.commitHash}
          </Typography>
        </div>
      </BknUserMenu>
    );
  }
}
