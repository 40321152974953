import {authentication, authorization} from '@onsmart/auth-client';
import {call, spawn} from 'redux-saga/effects';

import {environment} from 'config';
import {safe} from 'utils';

import {appSaga} from './global';
import {geofenceSaga} from './modules/geofence';
import {geofenceCollectionSaga} from './modules/geofenceCollection';

function* authSaga() {
  const ACCOUNT_URL = environment.settings.auth.account;
  const AUTH_BASE_URL = environment.settings.auth.gateway;
  const CORE_API_URL = environment.settings.apiUrl.coreApi;

  // Authentication Flow
  yield call(authentication.sagas.authenticationFlow, ACCOUNT_URL, AUTH_BASE_URL);

  // Authorization Flow
  const accessToken = yield call(authentication.getToken);
  yield call(authorization.sagas.userFetch, accessToken, CORE_API_URL);
}

export const rootSaga = safe(function* () {
  yield call(authSaga);
  const sagas = [appSaga, geofenceSaga, geofenceCollectionSaga];

  yield sagas.map((saga) => spawn(saga));
});
