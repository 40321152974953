import * as React from 'react';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Action} from 'redux';

import {actions as globalActions} from 'config/redux/global';
import {Breadcrumb} from 'models';

interface PageOptions {
  breadcrumbs?: Breadcrumb[];
  title: string;
}

interface PageProps extends MapDispatchToProps {}

interface MapDispatchToProps {
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => Action;
  setPageTitle: (title: string) => Action;
}

const mapDispatchToProps = (dispatch) => ({
  setBreadcrumbs: (breadcrumbs) => dispatch(globalActions.setBreadcrumbs(breadcrumbs)),
  setPageTitle: (title) => dispatch(globalActions.setPageTitle(title)),
});

export const page = (options: PageOptions) => (Component) => {
  return connect<{}, MapDispatchToProps, {}>(null, mapDispatchToProps)(
    class Page extends PureComponent<PageProps, {}> {
      componentDidMount() {
        const {setBreadcrumbs, setPageTitle} = this.props;

        const breadcrumbs = options.breadcrumbs || [options.title];

        setBreadcrumbs(breadcrumbs);
        setPageTitle(options.title);
      }

      render() {
        return <Component {...this.props} />;
      }
    },
  ) as any;
};
