import * as React from 'react';
import FlatButton from 'material-ui/Button';
import Dialog, {DialogActions, DialogContent} from 'material-ui/Dialog';
import {withStyles} from 'material-ui/styles';
import Typography from 'material-ui/Typography';

import {styles} from './RemoveForm.style';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  opened: boolean;
  entity: string;
  entityName: string;
  classes?: any;
};

const RemoveFormViewComponent = (props: Props) => (
  <div>
    <Dialog className={props.classes.dialog} open={!!props.opened} maxWidth={false}>
      <Title classes={props.classes} entity={props.entity} />

      <DialogContent className={props.classes.dialogContent}>
        <SubTitle classes={props.classes}>{props.entityName}</SubTitle>
        <Typography className={props.classes.dialogParagraph}>
          Are you sure you want to remove this {props.entity.toLowerCase()}?
          {props.entity === 'Collection'
            ? ` All the custom geofences in it will be permanently removed and this action cannot be undone.`
            : ` This action can\'t be undone.`}
        </Typography>
      </DialogContent>

      <DialogActions className="footer">
        <FlatButton onClick={props.onCancel} style={{color: '#8007D4'}}>
          No
        </FlatButton>
        <FlatButton onClick={props.onConfirm} style={{color: '#8007D4'}}>
          Yes
        </FlatButton>
      </DialogActions>
    </Dialog>
  </div>
);

const Title = (props) => (
  <div className={props.classes.header}>
    <Typography variant="title">Remove {props.entity}</Typography>
  </div>
);

const SubTitle = (props) => (
  <Typography variant="subheading" className={props.classes.subHeader}>
    {props.children}
  </Typography>
);

export const RemoveFormView = withStyles(styles)(RemoveFormViewComponent);
