import {Query} from 'bkn-ui';
import {createAction} from 'redux-act';

import {GeofenceCollectionItem, GeofenceCollections} from 'models';

export const actions = {
  fetchData: createAction('geofenceCollection/FETCH_DATA_REQUESTED'),
  setData: createAction<GeofenceCollections>('geofenceCollection/SET_DATA'),
  setTotal: createAction<number>('geofenceCollection/TOTAL_CHANGE'),

  setQuery: createAction<Query>('geofenceCollection/QUERY_CHANGE'),
  setSearchTerm: createAction<string>('geofenceCollection/SEARCH_TERM_CHANGE'),
  setSearchResult: createAction<GeofenceCollections>('geofenceCollection/SET_SEARCH_RESULT'),

  addCollection: createAction<string>('geofenceCollection/ADD_COLLECTION'),
  setSelectedCollection: createAction<string>('geofenceCollection/SET_SELECTED_COLLECTION'),

  setGeofenceCollectionItem: createAction<GeofenceCollectionItem>(
    'geofenceCollection/SET_GEOFENCE_COLLECTION_ITEM',
  ),
  updateCollection: createAction<Partial<GeofenceCollectionItem>>(
    'geofenceCollection/UPDATE_COLLECTION',
  ),
  removeCollection: createAction('geofenceCollection/REMOVE_COLLECTION'),
  removeCollectionItem: createAction<string>('geofenceCollection/REMOVE_COLLECTION_ITEM'),

  exportCollection: createAction('geofenceCollection/EXPORT_COLLECTION'),
};
