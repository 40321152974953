import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';
import {MuiThemeProvider} from 'material-ui/styles';

import 'styles';
import {muiTheme} from 'utils';

import RavenService from './services/RavenService';

RavenService.Raven.context(function() {
  const {App} = require('components/App');
  const {store} = require('config/redux');
  const {history} = require('config');
  const {swConnect} = require('./service-worker-connect');

  swConnect(store);

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={muiTheme}>
          <App />
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app'),
  );
});
