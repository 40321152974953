import * as React from 'react';
import AddIcon from 'material-ui-icons/Add';
import RemoveIcon from 'material-ui-icons/Delete';
import EditIcon from 'material-ui-icons/Edit';
import ExportIcon from 'material-ui-icons/FileDownload';
import MoreVertIcon from 'material-ui-icons/MoreVert';
import IconButton from 'material-ui/IconButton';
import {ListItemIcon, ListItemText} from 'material-ui/List';
import Menu, {MenuItem} from 'material-ui/Menu';
import {withStyles} from 'material-ui/styles';

import {styles} from './Options.style';

export const OptionsViewComponent = (props) => (
  <div>
    <IconButton
      className={props.classes.actionBtn}
      aria-label="More"
      aria-owns={props.anchorEl ? 'long-menu' : null}
      aria-haspopup="true"
      onClick={props.onClick}
    >
      <MoreVertIcon />
    </IconButton>

    <Menu
      id="long-menu"
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      PaperProps={{style: {maxHeight: 48 * 4.5}}}
    >
      {props.onCreate && (
        <MenuItem onClick={props.onCreate}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary={
              <span className={props.classes.actionMenuItemText}>Add {props.createEntity}</span>
            }
          />
        </MenuItem>
      )}

      {props.onEdit && (
        <MenuItem onClick={props.onEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary={
              <span className={props.classes.actionMenuItemText}>Rename {props.editEntity}</span>
            }
          />
        </MenuItem>
      )}

      {props.onRemove && (
        <MenuItem onClick={props.onRemove} disabled={!props.removeValidation}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary={
              <span className={props.classes.actionMenuItemText}>Remove {props.removeEntity}</span>
            }
          />
        </MenuItem>
      )}

      {props.onExport && (
        <MenuItem onClick={props.onExport} disabled={!props.exportValidation}>
          <ListItemIcon>
            <ExportIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary={<span className={props.classes.actionMenuItemText}>Export GeoJSON</span>}
          />
        </MenuItem>
      )}
    </Menu>
  </div>
);

export const OptionsView = withStyles(styles)(OptionsViewComponent);
