import {environment, history} from 'config';

export const updateQueryStringParam = (key: string, value: string) => {
  const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
  const hash = history.location.hash;
  const urlQueryString = history.location.search;
  const newParam = key + '=' + value;
  let params = '?' + newParam;

  if (urlQueryString) {
    const updateRegex = new RegExp('([?&])' + key + '[^&]*');
    const removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');

    if (!value) {
      params = urlQueryString.replace(removeRegex, '$1');
      params = params.replace(/[&;]$/, '');
    } else if (urlQueryString.match(updateRegex) !== null) {
      // If param exists already, update it
      params = urlQueryString.replace(updateRegex, '$1' + newParam);
    } else {
      // Otherwise, add it to end of query string
      params = urlQueryString + '&' + newParam;
    }
  }

  const oldUrl = `${history.location.pathname}${history.location.search}`;
  const newUrl = `${history.location.pathname}${params}`;

  if (oldUrl !== newUrl) {
    history.replace(`${history.location.pathname}${params}`);
  }
};

interface KeyValue {
  key: string;
  value: string;
}

export const getUrlParams = () => {
  return location.search
    .substr(1)
    .split('&')
    .map((param) => {
      const keyValue = param.split('=');
      return {key: keyValue[0], value: keyValue[1]};
    })
    .reduce((accu: any, curr: KeyValue) => {
      accu[curr.key] = curr.value;
      return accu;
    }, {});
};

export const getDomain = () => {
  if (window.location.hostname === 'localhost') {
    return 'localhost';
  }
  const regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}:?[0-9]{0,5}$');
  return regexParse.exec(window.location.hostname)[0];
};
export const getSubdomain = () => {
  if (window.location.hostname === 'localhost') {
    return '';
  }
  const regexParse = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
  return regexParse.exec(window.location.hostname)[0];
};
export const getPrefix = () => {
  const subdomain = getSubdomain();
  if (subdomain === 'geofence.') {
    return '';
  }
  return subdomain;
};
export const getAccountLink = () => {
  let link = '';
  if (environment.currentType !== 'production') {
    if (window.location.hostname === 'localhost') {
      link = 'http://localhost';
    } else {
      link = generateLink('account');
    }
    return `${link}:9000`;
  } else {
    return generateLink('account');
  }
};
export const generateLink = (app: string) => {
  const prefix = getPrefix();
  const domain = getDomain();
  const protocol = `${window.location.protocol}//`;
  return `${protocol}${prefix}${app}.${domain}`;
};
