import {createSelector} from 'reselect';
import {$call, $PropertyType, Overwrite} from 'utility-types';

import {Dispatch, RootState} from 'config/redux';
import {actions as globalActions} from 'config/redux/global';
import {actions as geofencesActions} from 'config/redux/modules/geofence';
import {actions as collectionsActions} from 'config/redux/modules/geofenceCollection';
import {GeofenceCollectionItem} from 'models';

export const mapStateToProps = (state: RootState) => ({
  collections: state.geofenceCollection.data,
  selectedCollection: state.geofenceCollection.selected,
  geofences: state.geofence.data,
  selectedGeofence: state.geofence.selected,
  query: state.geofenceCollection.query,
  searchTerm: state.geofenceCollection.searchTerm,
});

const mapStateToPropsNope = $call(mapStateToProps);
export type MapStateToProps = typeof mapStateToPropsNope;

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCollections: () => dispatch(collectionsActions.fetchData()),
  fetchGeofences: () => dispatch(geofencesActions.fetchData()),
  addCollection: (name: string) => dispatch(collectionsActions.addCollection(name)),
  addGeofence: (name: string) => dispatch(geofencesActions.prepareAddGeofence(name)),
  editCollection: (name: Partial<GeofenceCollectionItem>) =>
    dispatch(collectionsActions.updateCollection(name)),
  removeCollection: () => dispatch(collectionsActions.removeCollection()),
  exportCollection: () => dispatch(collectionsActions.exportCollection()),
  setSelectedCollection: (id: string) => dispatch(collectionsActions.setSelectedCollection(id)),
  setSelectedGeofence: (id: string) => dispatch(geofencesActions.setSelectedGeofence(id)),
});

const mapDispatchToPropsNope = $call(mapDispatchToProps);
export type MapDispatchToProps = typeof mapDispatchToPropsNope;
