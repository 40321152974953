import {routerMiddleware as reactRouterReduxMiddleware} from 'react-router-redux';
import {applyMiddleware, createStore} from 'redux';
import {Action} from 'redux-act';
import createSagaMiddleware from 'redux-saga';

import {history} from 'config';

import RavenService from '../../services/RavenService';
import {rootReducer, RootState} from './rootReducer';
import {rootSaga} from './rootSaga';

const routerMiddleware = reactRouterReduxMiddleware(history);
const sagaMiddleware = createSagaMiddleware({
  onError: (err) => RavenService.Raven.captureException(err),
});

export type RootState = RootState;
export type Dispatch = (action: Action<any>) => void;

export const store = createStore<RootState>(
  rootReducer,
  applyMiddleware(routerMiddleware, sagaMiddleware, RavenService.middleware),
);

const rootTask = sagaMiddleware.run(rootSaga);
rootTask.done.catch((err) => {
  throw err;
});
