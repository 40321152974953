import {Query} from 'bkn-ui';
import {createAction} from 'redux-act';

import {GeofenceItem, Geofences} from 'models';

export const actions = {
  fetchData: createAction('geofence/FETCH_DATA_REQUESTED'),
  setData: createAction<Geofences>('geofence/SET_DATA'),

  setTotal: createAction<number>('geofence/TOTAL_CHANGE'),

  setQuery: createAction<Query>('geofence/QUERY_CHANGE'),
  setSearchTerm: createAction<string>('geofence/SEARCH_TERM_CHANGE'),
  setSearchResult: createAction<Geofences>('geofence/SET_SEARCH_RESULT'),

  setSelectedGeofence: createAction<string>('geofence/SET_SELECTED_GEOFENCE'),

  prepareAddGeofence: createAction<string>('geofence/PREPARE_ADD_GEOFENCE'),
  prepareAddGeofenceSuccess: createAction<string>('geofence/PREPARE_ADD_GEOFENCE_SUCCESS'),
  updateGeofence: createAction<Partial<GeofenceItem>>('geofence/UPDATE_GEOFENCE'),
  setGeofenceItem: createAction<GeofenceItem>('geofence/SET_GEOFENCE_ITEM'),
  removeGeofence: createAction('geofence/REMOVE_GEOFENCE'),
  removeGeofenceItem: createAction<string>('geofence/REMOVE_GEOFENCE_ITEM'),
};
