import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import {
  Divider,
  IconButton,
  MenuItem,
  Popover,
  StyledComponentProps,
  withStyles,
} from 'material-ui';
import KeyboardArrowDown from 'material-ui-icons/KeyboardArrowDown';
import Sniffr from 'sniffr';

import {getUserRoles} from 'components/common/hoc';
import {styles} from 'components/common/ServiceNavigationMenu/ServiceNavigationMenu.style';
import {generateLink} from 'utils/url';

class ServiceNavigationMenuComponent extends Component<
  {currentUserRole: string[]} & StyledComponentProps
> {
  state: any = {
    anchorEl: null,
  };

  handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handlePopoverClick = (evt: React.MouseEvent<HTMLElement>, appName: string) => {
    this.setState({anchorEl: null});
    const url = generateLink(appName);
    if (evt.ctrlKey || evt.metaKey) {
      window.open(url, '_blank');
    } else {
      window.open(url, '_self');
    }
  };

  isAdmin = () => {
    const {currentUserRole} = this.props;
    return currentUserRole.includes('admin');
  };

  isDmpWithMad = () => {
    const {currentUserRole} = this.props;
    const isDmpEditor = currentUserRole.includes('dmp:editor');
    const isMad = currentUserRole.some((r) => r.startsWith('mad'));

    return !isDmpEditor || isMad;
  };

  isUserOnMac = () => {
    const ua = navigator.userAgent;
    const s = new Sniffr();
    s.sniff(ua);
    return s.os.name === 'macos';
  };

  renderAdminMenu = () => {
    const {classes} = this.props;
    return (
      this.isAdmin() && (
        <span>
          {/* <MenuItem onClick={(ev) => this.handlePopoverClick(ev, 'refinery')}>
            POI Refinery
          </MenuItem>
          <MenuItem onClick={(ev) => this.handlePopoverClick(ev, 'taxonomy')}>Taxonomy</MenuItem> */}
          <MenuItem onClick={(ev) => this.handlePopoverClick(ev, 'admin')}>Admin Console</MenuItem>
        </span>
      )
    );
  };

  renderPopOver = () => {
    const {classes} = this.props;
    const {anchorEl} = this.state;
    return (
      <Popover
        className={classes!.popOverContainer}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorReference={'anchorEl'}
        onClose={this.handleClose}
        marginThreshold={0}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem disabled onClick={this.handleClose}>
          Geofence
        </MenuItem>
        <Divider />
        <MenuItem disabled className={classes!.helpCaption}>
          {this.isUserOnMac() ? 'New tab? ⌘+Click' : 'New tab? CTRL+Click'}
        </MenuItem>
        {(this.isAdmin() || this.isDmpWithMad()) && (
          <MenuItem onClick={(ev) => this.handlePopoverClick(ev, 'mad')}>
            MAD Curation Center
          </MenuItem>
        )}
        {(this.isAdmin() || this.isDmpWithMad()) && (
          <MenuItem onClick={(ev) => this.handlePopoverClick(ev, 'dmp')}>
            smartSCOUT&trade;
          </MenuItem>
        )}
        {this.renderAdminMenu()}
      </Popover>
    );
  };

  render() {
    const {classes} = this.props;
    const {anchorEl} = this.state;

    return (
      (this.isDmpWithMad() || this.isAdmin()) && (
        <div id="ServiceNavigationManuBtn">
          <IconButton
            color="primary"
            className={`btn btn-icon ${classes!.btnMainNavLeftTitle}`}
            aria-label="More"
            aria-owns={anchorEl ? 'long-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <KeyboardArrowDown />
          </IconButton>

          {this.renderPopOver()}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserRole: getUserRoles(state),
});

export const ServiceNavigationMenu = withStyles(styles)(
  connect(mapStateToProps)(ServiceNavigationMenuComponent),
);
