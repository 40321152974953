import {Query} from 'bkn-ui';
import {combineReducers} from 'redux';
import {Action, createAction, createReducer} from 'redux-act';
import {delay, eventChannel} from 'redux-saga';
import {call, fork, put, select, take, takeLatest} from 'redux-saga/effects';
import uuid from 'uuid/v4';

import {RootState} from 'config/redux';
import {GeofenceItem, Geometry} from 'models';
import {safe} from 'utils';

import {GeofenceRestService} from '../../../../services';
import {actions as globalActions} from '../../global';
import {actions} from './actions';

// SAGAS

export const fetchDataSaga = safe(function*(action: Action<string>) {
  try {
    yield put(actions.setTotal(0));
    yield put(actions.setData(null));

    yield put(globalActions.showLoading());
    const collection = yield select((state: RootState) => state.geofenceCollection.selected);
    const {data, total} = yield GeofenceRestService.getInstance().find({
      collectionId: collection,
      fields: ['name', 'collectionId', 'centroid', 'updatedAt', 'geometry'],
    });

    yield put(actions.setTotal(total));
    yield put(actions.setData(data));
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const updateGeofenceSaga = safe(function*(action: Action<GeofenceItem>) {
  try {
    yield put(globalActions.showLoading());
    const {id, ...props} = action.payload;
    const geofence = yield select((state: RootState) => state.geofence.data[id]);

    if (!geofence.updatedAt) {
      const collection = yield select((state: RootState) => state.geofenceCollection.selected);
      const newGeofence = {...geofence, ...props, collectionId: collection};

      delete newGeofence.id;
      const res = yield GeofenceRestService.getInstance().create(newGeofence);

      yield put(actions.setGeofenceItem(res));
      yield put(actions.setSelectedGeofence(res.id));
      yield put(actions.removeGeofenceItem(id));
    } else {
      yield put(actions.setGeofenceItem({...geofence, ...props}));
      const res = yield GeofenceRestService.getInstance().update(id, {...geofence, ...props});
      yield put(actions.setGeofenceItem(res));
    }

    yield put(globalActions.showMessage('Geofence saved'));
  } catch (e) {
    if (e.message === 'mapper_parsing_exception') {
      yield put(globalActions.showMessage('Invalid Geometry. Please check the polygons'));
    } else {
      throw e;
    }
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const removeGeofenceSaga = safe(function*() {
  try {
    yield put(globalActions.showLoading());
    const geofenceId = yield select((state: RootState) => state.geofence.selected);
    const geofence = yield select((state: RootState) => state.geofence.data[geofenceId]);

    yield put(actions.removeGeofenceItem(geofenceId));

    if (geofence.updatedAt) {
      try {
        yield GeofenceRestService.getInstance().remove(geofenceId);
        yield put(globalActions.showMessage('Geofence removed'));
      } catch {
        yield put(globalActions.showMessage('Error removing geofence'));
        yield put(actions.setGeofenceItem(geofence));
      }
    } else {
      yield put(globalActions.showMessage('Geofence removed'));
    }
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const prepareAddGeofenceSaga = safe(function*(action: Action<string>) {
  try {
    yield put(globalActions.showLoading());
    const id = uuid();

    yield put(
      actions.setGeofenceItem({
        id,
        name: action.payload,
        geometry: null as Geometry,
      }),
    );
    yield put(actions.setSelectedGeofence(id));
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const geofenceFlow = safe(function*() {
  yield takeLatest(actions.fetchData, fetchDataSaga);
  yield takeLatest(actions.prepareAddGeofence, prepareAddGeofenceSaga);
  yield takeLatest(actions.updateGeofence, updateGeofenceSaga);
  yield takeLatest(actions.removeGeofence, removeGeofenceSaga);
});

export const geofenceSaga = safe(function*() {
  yield fork(geofenceFlow);
});
