import * as React from 'react';
import {connect} from 'react-redux';
import * as deepEqual from 'fast-deep-equal';
import * as _get from 'lodash.get';
import {$PropertyType} from 'utility-types';

import {GeofenceItem, Geometry} from 'models';

import {CollectionListProps} from '../CollectionList';
import {GeofenceView} from './Geofence.view';
import {MapDispatchToProps, mapDispatchToProps} from './selectors';

type GeofenceContainerProps = {
  selectedGeofence: string;
};

type Props = CollectionListProps & MapDispatchToProps & GeofenceContainerProps;

type State = {viewshed: Geometry; geofenceId: string};

class GeofenceContainerComponent extends React.Component<Props> {
  state: State = {
    viewshed: {coordinates: [], type: 'MultiPolygon'},
    geofenceId: null as string,
  } as State;

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      nextProps.geofences !== this.props.geofences ||
      nextProps.selectedCollection !== this.props.selectedCollection ||
      nextProps.selectedGeofence !== this.props.selectedGeofence ||
      !deepEqual(this.state.viewshed, nextState.viewshed)
    );
  }

  componentWillReceiveProps(nextProps: Props) {
    const nextCoords = _get(nextProps.geofences, `${nextProps.selectedGeofence}.geometry`);

    const stateCoords = this.state.viewshed || [];

    if (!deepEqual(stateCoords, nextCoords)) {
      this.setState({viewshed: nextCoords});
    }

    const nextGeofence = _get(nextProps.geofences, `${nextProps.selectedGeofence}.id`);

    if (this.state.geofenceId !== nextGeofence) {
      this.setState({geofenceId: nextGeofence});
    }
  }

  isValid() {
    const stateCoordinates = _get(this.state.viewshed, `coordinates`) || [];
    const geofenceCoordinates =
      _get(this.props.geofences, `${this.props.selectedGeofence}.geometry.coordinates`) || [];

    const equal = deepEqual(stateCoordinates, geofenceCoordinates);

    return stateCoordinates.length > 0 && !equal;
  }

  render() {
    return (
      <GeofenceView
        {...this.props}
        geofenceId={this.state.geofenceId}
        onLocationChange={this.onLocationChange}
        onSave={this.onSave}
        onEdit={this.onEdit}
        onRemove={this.onRemove}
        currentViewshed={this.state.viewshed}
        valid={this.isValid()}
      />
    );
  }

  onSave = () => {
    this.props.saveGeofence({id: this.props.selectedGeofence, geometry: this.state.viewshed});
  };

  onEdit = (name: string) => {
    this.props.saveGeofence({id: this.props.selectedGeofence, name});
  };

  onRemove = () => {
    this.props.removeGeofence();
  };

  onLocationChange = (viewshed: Geometry) => {
    this.setState({viewshed});
    if (this.isValid()) {
      this.onSave();
    }
  };
}

export const GeofenceContainer = connect(null, mapDispatchToProps)(GeofenceContainerComponent);
