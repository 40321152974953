import {ReactNode} from 'react';
import {authentication} from '@onsmart/auth-client';
import {$call, Overwrite} from 'utility-types';

import {Dispatch, RootState} from 'config/redux';
import {actions} from 'config/redux/global';

import {User} from '../../../models/User';

type Message = {title: string; action: string};

export const mapStateToProps = (state: RootState) => ({
  user: state.authorization.user || ({} as User),
  loading: state.app.loading,
  breadcrumbs: state.app.breadcrumbs,
  pageTitle: state.app.pageTitle,
  messageOpened: state.app.messageOpened,
  message: state.app.message || ({} as Message),
  hasServiceWorkerUpdate: state.app.hasServiceWorkerUpdate,
  hasServiceWorkerQuotaExceeded: state.app.hasServiceWorkerQuotaExceeded,
  activeNavLink: location.hash,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(authentication.actions.logout()),
  hideMessage: () => dispatch(actions.hideMessage()),
  wontRefreshApp: () => dispatch(actions.swWontUpdate()),
  onRefresh: () => dispatch(actions.swRefresh()),
});

const mapStateToPropsNope = $call(mapStateToProps);
export type MapStateToPropsType = typeof mapStateToPropsNope;

const mapDispatchToPropsNope = $call(mapDispatchToProps);
export type MapDispatchToProps = typeof mapDispatchToPropsNope;

export type MapStateToProps = Overwrite<MapStateToPropsType, {message: Message}>;
