import {environment} from 'config';
import {Geofences} from 'models';

import {RestService} from './RestService';

export class GeofenceRestService extends RestService<Geofences> {
  private static instance: GeofenceRestService;

  private constructor() {
    super({
      baseUrl: environment.settings.apiUrl.geofence,
      apiUrl: '/custom',
    });
  }

  static getInstance() {
    return new GeofenceRestService();
  }

  public async find(query: {collectionId: string; fields: string[]}) {
    const esQuery = {
      size: 100,
      query: {
        bool: {
          must: [
            {
              simple_query_string: {
                query: '*',
                default_operator: 'and',
              },
            },
            {
              term: {
                collectionId: query.collectionId,
              },
            },
          ],
        },
      },
      _source: query.fields,
    };

    const res = await this.axiosInstance.post('/search', esQuery);
    return res.data;
  }
}
