import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import _get from 'lodash.get';
import {createSelector} from 'reselect';

interface Props {
  requiredRole?: string;
  hideForRole?: string;
}

class HasRoleComponent extends Component<Props & {currentUserRole: string[]}> {
  render() {
    const {children, currentUserRole} = this.props;

    if (this.isAdmin() || this.authorized()) {
      return <div className="wrapper-has-role"> {children} </div>;
    }

    return null;
  }

  isAdmin() {
    return this.props.currentUserRole.includes('admin');
  }

  authorized() {
    const {currentUserRole, requiredRole, hideForRole} = this.props;
    const split = (str: string) => (str || '').trim().split(',');

    let roles = [] as string[];

    if (hideForRole) {
      roles = split(hideForRole);

      return roles.every((r) => !currentUserRole.includes(r));
    }

    roles = split(requiredRole);

    return roles.every((r) => currentUserRole.some((c) => c.startsWith(r)));
  }
}

const getRoles = (state) => {
  return _get(state, 'user.app_metadata.permissions.ofm.role') as string | string[];
};

export const getUserRoles = createSelector([getRoles], (roles) => {
  return typeof roles === 'string' ? [roles] : [...(roles || '')].filter((i) => i);
});

const mapStateToProps = (state) => ({
  currentUserRole: getUserRoles(state),
});

export const HasRole = connect(mapStateToProps)(HasRoleComponent);
