import * as React from 'react';
import {FormControl} from 'material-ui/Form';
import Input, {InputLabel} from 'material-ui/Input';
import {withStyles} from 'material-ui/styles';
import TextField from 'material-ui/TextField';

import {styles} from './CustomInput.style';

export const CustomInputComponent = (props) => {
  const {classes, ...filteredProps} = props;

  return (
    <div className={classes.container}>
      <TextField
        {...filteredProps}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.textFieldRoot,
            input: classes.textFieldInput,
          },
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.textFieldFormLabel,
        }}
      />
    </div>
  );
};

export const CustomInput = withStyles(styles as {})(CustomInputComponent);
