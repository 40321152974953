import {StyleRulesCallback} from 'material-ui/styles';

export const styles: StyleRulesCallback = (theme) => ({
  btnMainNavLeftTitle: {
    height: '54px',
    padding: '1rem',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'initial',
    },
  },
  btnMainNavLeftTitleItem: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  helpCaption: {
    textTransform: 'uppercase',
    fontSize: 10,
    paddingBottom: '0px',
  },
  popoverOverwrite: {
    top: 0,
  },
});
