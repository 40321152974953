import {Action} from 'redux-act';
import {call} from 'redux-saga/effects';

import RavenService from '../services/RavenService';

type Saga<T> = (action?: Action<T>) => IterableIterator<any>;
type Safe = <T>(
  saga: Saga<T>,
  recovery?: (action: Action<T>, err: Error) => IterableIterator<any> | void,
) => Saga<T>;

export const safe: Safe = (saga, recovery?) =>
  function*(action: Action<any | {}>) {
    try {
      return yield call(saga, action);
    } catch (err) {
      console.error('Sagas Error', err);
      if (recovery) {
        yield call(recovery, action, err);
      } else {
        RavenService.Raven.captureException(err);
      }
    }
  };
