export const layers = {
  state: {
    url: 'mapbox://shfishburn.bkn-geofence-state',
    sourceLayer: 'BKN_GEOFENCE_STATE',
    minZoom: 3,
    label: 'State',
  },
  county: {
    url: 'mapbox://shfishburn.bkn-geofence-county',
    sourceLayer: 'BKN_GEOFENCE_COUNTY',
    minZoom: 4,
    label: 'County',
  },
  zip: {
    url: 'mapbox://shfishburn.bkn-geofence-zip',
    sourceLayer: 'BKN_GEOFENCE_ZIP',
    minZoom: 4,
    label: 'Zip Code',
  },
  place: {
    url: 'mapbox://shfishburn.bkn-geofence-place',
    sourceLayer: 'BKN_GEOFENCE_PLACE',
    minZoom: 4,
    label: 'Place',
  },
  csa: {
    url: 'mapbox://shfishburn.bkn-geofence-csa',
    sourceLayer: 'BKN_GEOFENCE_CSA',
    minZoom: 5,
    label: 'CSA',
  },
  cbsa: {
    url: 'mapbox://shfishburn.bkn-geofence-cbsa',
    sourceLayer: 'BKN_GEOFENCE_CBSA',
    minZoom: 5,
    label: 'CBSA',
  },
  'census-tract': {
    url: 'mapbox://shfishburn.bkn-geofence-census-tract',
    sourceLayer: 'BKN_GEOFENCE_CENSUS_TRACT',
    minZoom: 5,
    label: 'Census Tract',
  },
  'block-group': {
    url: 'mapbox://shfishburn.bkn-geofence-block-group',
    sourceLayer: 'BKN_GEOFENCE_BLOCK_GROUP',
    minZoom: 5,
    label: 'Block Group',
  },
  // neighborhood: {
  //   url: 'mapbox://shfishburn.bkn-geofence-neighborhood',
  //   sourceLayer: 'BKN_GEOFENCE_NEIGHBORHOOD',
  //   minZoom: 6,
  //   label: 'Neighborhood',
  // },
};

export const neighborhoodLayerSubtypes = {
  NEIGHBORHOOD: 'Neighborhood',
  MACRO_NEIGHBORHOOD: 'Macro Neighborhood',
  SUB_NEIGHBORHOOD: 'Sub Neighborhood',
};
