import {routerReducer, RouterState} from 'react-router-redux';
import {authorization} from '@onsmart/auth-client';
import {combineReducers} from 'redux';

import {app, AppState} from './global/reducers';
import {geofence, GeofenceState} from './modules/geofence';
import {geofenceCollection, GeofenceCollectionState} from './modules/geofenceCollection';

export type AuthorizationReducer = ReturnType<typeof authorization.reducer.authorization>;

export type RootState = {
  app: AppState;
  geofence: GeofenceState;
  geofenceCollection: GeofenceCollectionState;
  router: RouterState;
  authorization: AuthorizationReducer;
};

export const rootReducer = combineReducers<RootState>({
  app,
  geofence,
  geofenceCollection,
  router: routerReducer,
  ...authorization.reducer,
});
