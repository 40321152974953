import * as React from 'react';
import {ChangeEvent} from 'react';
import {connect} from 'react-redux';
import {$call} from 'utility-types';

import {RootState} from 'config/redux/rootReducer';

import {AddFormView} from './AddForm.view';
import {MapStateToProps, mapStateToProps} from './selectors';

type GeofenceContainerProps = {
  selectedGeofence: string;
};

type Props = {
  onSubmit: (value: string) => void;
  onCancel: () => void;
  opened: boolean;
  title: string;
  value?: string;
} & MapStateToProps;

type State = {
  value: string;
  nameInputRef: HTMLInputElement;
};

class AddFormContainerComponent extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    value: '',
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
      nameInputRef: null as HTMLInputElement,
    };
  }

  componentDidUpdate() {
    if (this.state.nameInputRef) {
      setTimeout(() => {
        this.state.nameInputRef.focus();
      }, 300);
    }
  }

  render() {
    return (
      <AddFormView
        {...this.props}
        handleNameChange={this.handleNameChange}
        value={this.state.value}
        onSubmit={this.handleSave}
        setNameInputRef={this.setNameInputRef}
      />
    );
  }

  handleSave = () => {
    this.props.onSubmit(this.state.value);
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({value: e.target.value});
  };

  setNameInputRef = (ref: HTMLInputElement) => {
    this.setState({nameInputRef: ref});
  };
}

export const AddFormContainer = connect(mapStateToProps, null)(AddFormContainerComponent);
