import * as React from 'react';
import {$PropertyType} from 'utility-types';

import {CustomProps} from '../../Custom.container';
import {CollectionListView} from './CollectionList.view';

export type CollectionListProps = {
  collections: $PropertyType<CustomProps, 'collections'>;
  geofences: $PropertyType<CustomProps, 'geofences'>;
  addCollection: $PropertyType<CustomProps, 'addCollection'>;
  addGeofence: $PropertyType<CustomProps, 'addGeofence'>;
  editCollection: $PropertyType<CustomProps, 'editCollection'>;
  removeCollection: $PropertyType<CustomProps, 'removeCollection'>;
  selectedCollection: $PropertyType<CustomProps, 'selectedCollection'>;
  selectedGeofence: $PropertyType<CustomProps, 'selectedGeofence'>;
  exportCollection: $PropertyType<CustomProps, 'exportCollection'>;
  handleCollectionClick: (id: string) => void;
  handleGeofenceClick: (id: string) => void;
};

type State = {
  creatingCollection: boolean;
  creatingGeofence: boolean;
};

export class CollectionListContainer extends React.Component<CollectionListProps> {
  state: State = {
    creatingCollection: false,
    creatingGeofence: false,
  };

  componentWillReceiveProps(nextProps: CollectionListProps) {
    this.setDefaultValues(nextProps);
  }

  render() {
    return (
      <CollectionListView
        selectedCollection={this.props.selectedCollection}
        selectedGeofence={this.props.selectedGeofence}
        collections={this.props.collections}
        geofences={this.props.geofences}
        handleToggle={this.handleToggle}
        onCreateCollection={this.props.addCollection}
        onCreateGeofence={this.props.addGeofence}
        onEditCollection={this.onEditCollection}
        onRemoveCollection={this.props.removeCollection}
        onExportCollection={this.props.exportCollection}
        creatingCollection={this.state.creatingCollection}
        creatingGeofence={this.state.creatingGeofence}
        onAddCollectionClick={() => this.onHandleCollectionCreating(true)}
        onAddGeofenceClick={() => this.onHandleGeofenceCreation(true)}
        onCloseCollectionModal={() => this.onHandleCollectionCreating(false)}
        onCloseGeofenceModal={() => this.onHandleGeofenceCreation(false)}
      />
    );
  }

  onHandleCollectionCreating = (value: boolean) => {
    this.setState({creatingCollection: value});
  };

  onHandleGeofenceCreation = (value: boolean) => {
    this.setState({creatingGeofence: value});
  };

  handleToggle = (key: string, value: string) => () => {
    if (key === 'collection') {
      this.props.handleCollectionClick(value);
    }

    if (key === 'geofence') {
      this.props.handleGeofenceClick(value);
    }
  };

  setDefaultValues = (nextProps: CollectionListProps) => {
    if (
      (!this.props.collections && nextProps.collections !== null) ||
      (nextProps.collections !== null &&
        nextProps.selectedCollection !== null &&
        !nextProps.collections[nextProps.selectedCollection])
    ) {
      const nextCollectionsKeys = Object.keys(nextProps.collections);

      const id = nextCollectionsKeys[0] || null;

      this.props.handleCollectionClick(id);
    }

    if (
      (!this.props.geofences && nextProps.geofences !== null) ||
      (nextProps.geofences !== null &&
        nextProps.selectedGeofence !== null &&
        !nextProps.geofences[nextProps.selectedGeofence])
    ) {
      const nextGeofencesKeys = Object.keys(nextProps.geofences);

      const id = nextGeofencesKeys[0] || null;

      this.props.handleGeofenceClick(id);
    }
  };

  onEditCollection = (name: string) => {
    this.props.editCollection({id: this.props.selectedCollection, name});
  };
}
