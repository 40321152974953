import * as React from 'react';
import {AddFormContainer} from '../AddForm';
import {RemoveFormView} from '../RemoveForm';
import {OptionsView} from './Options.view';

type State = {
  anchorEl: HTMLElement;
  creating: boolean;
  editing: boolean;
  removing: boolean;
};

type Props = {
  creating?: boolean;
  createEntity?: string;
  editEntity?: string;
  editEntityName?: string;
  removeEntity?: string;
  removeEntityName?: string;
  removeValidation?: boolean;
  onSubmitCreate?: (value: string) => void;
  onSubmitEdit?: (value: string) => void;
  onSubmitRemove?: () => void;
  onExport?: () => void;
  onClose?: () => void;
  exportValidation?: boolean;
  selectedCollection: string;
  selectedGeofence: string;
};

export class OptionsContainer extends React.Component<Props> {
  state: State = {
    anchorEl: null as HTMLElement,
    creating: false,
    editing: false,
    removing: false,
  };

  static defaultProps: Partial<Props> = {
    onClose: () => null,
  };

  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.creating && nextProps.creating) {
      this.setState({creating: true});
    }

    if (
      this.props.selectedCollection !== nextProps.selectedCollection ||
      this.props.selectedGeofence !== nextProps.selectedGeofence
    ) {
      this.onCancel();
    }
  }

  render() {
    const removeValidation =
      this.props.removeValidation !== undefined ? this.props.removeValidation : true;

    const exportValidation =
      this.props.exportValidation !== undefined ? this.props.exportValidation : true;

    return (
      <div style={{transform: 'translateX(18px)'}}>
        <OptionsView
          anchorEl={this.state.anchorEl}
          onClose={this.onClose}
          onClick={this.onClick}
          onCreate={this.props.onSubmitCreate && this.onCreate}
          onEdit={this.props.onSubmitEdit && this.onEdit}
          onRemove={this.props.onSubmitRemove && this.onRemove}
          onExport={this.props.onExport && this.onExport}
          createEntity={this.props.createEntity}
          editEntity={this.props.editEntity}
          removeEntity={this.props.removeEntity}
          removeValidation={removeValidation}
          exportValidation={exportValidation}
        />
        {!!this.state.creating && (
          <AddFormContainer
            onSubmit={this.onSubmitCreate}
            onCancel={this.onCancel}
            opened={this.state.creating}
            title={`Add ${this.props.createEntity}`}
          />
        )}
        {!!this.state.editing && (
          <AddFormContainer
            onSubmit={this.onSubmitEdit}
            onCancel={this.onCancel}
            opened={this.state.editing}
            value={this.props.editEntityName}
            title={`Rename ${this.props.editEntity}`}
          />
        )}
        {!!this.state.removing && (
          <RemoveFormView
            onConfirm={this.onSubmitRemove}
            onCancel={this.onCancel}
            opened={this.state.removing}
            entity={this.props.removeEntity}
            entityName={this.props.removeEntityName}
          />
        )}
      </div>
    );
  }

  onClose = () => {
    this.setState({anchorEl: null});
    this.props.onClose();
  };

  onClick = (ev: React.SyntheticEvent<{}>) => {
    this.setState({anchorEl: ev.currentTarget});
  };

  onCreate = () => {
    this.setState({creating: true, anchorEl: null});
  };

  onEdit = () => {
    this.setState({editing: true, anchorEl: null});
  };

  onExport = () => {
    this.setState({anchorEl: null});
    this.props.onExport();
  };

  onRemove = () => {
    this.setState({removing: true, anchorEl: null});
  };

  onCancel = () => {
    this.setState({creating: false, editing: false, removing: false, anchorEl: null});
    this.props.onClose();
  };

  onSubmitCreate = (value: string) => {
    this.props.onSubmitCreate(value);
  };

  onSubmitEdit = (value: string) => {
    this.props.onSubmitEdit(value);
    this.setState({editing: false});
  };

  onSubmitRemove = () => {
    this.props.onSubmitRemove();
  };
}
