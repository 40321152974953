import * as React from 'react';
import {ChangeEvent} from 'react';
import FlatButton from 'material-ui/Button';
import Dialog, {DialogActions, DialogContent} from 'material-ui/Dialog';
import {StyledComponentProps, withStyles} from 'material-ui/styles';
import TextField from 'material-ui/TextField';
import Typography from 'material-ui/Typography';

import {CustomInput} from 'components/common/CustomInput';

import {styles} from './AddForm.style';

type Props = {
  opened: boolean;
  title: string;
  value: any;
  loading: boolean;
  handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setNameInputRef: (ref: HTMLInputElement) => void;
  onSubmit: () => void;
  onCancel: () => void;
} & StyledComponentProps;

const AddFormViewComponent = (props: Props) => (
  <div>
    <Dialog className={props.classes.dialog} open={!!props.opened} maxWidth={false}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.onSubmit();
        }}
      >
        <Title classes={props.classes} title={props.title} />

        <DialogContent className={props.classes.dialogContent}>
          <CustomInput
            id="name"
            label="Name"
            required
            style={{width: '100%'}}
            value={props.value}
            onChange={props.handleNameChange}
            margin="normal"
            InputProps={{inputProps: {maxLength: 60}}}
            inputRef={props.setNameInputRef}
          />
        </DialogContent>

        <DialogActions className="footer">
          <FlatButton onClick={props.onCancel} style={{color: '#8007D4'}}>
            Cancel
          </FlatButton>
          <FlatButton
            disabled={!props.value.length || props.loading}
            type="submit"
            style={{color: '#8007D4'}}
          >
            Done
          </FlatButton>
        </DialogActions>
      </form>
    </Dialog>
  </div>
);

const Title = (props: {title: string} & StyledComponentProps) => (
  <div className={props.classes.header}>
    <Typography variant="title">{props.title}</Typography>
  </div>
);

export const AddFormView = withStyles(styles)(AddFormViewComponent);
